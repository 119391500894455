<template>
  <section class="menu computer">
    <div class="logo">
      <router-link to="/"
        ><img src="@/assets/images/logo.svg" alt=""
      /></router-link>
    </div>
    <div class="navigation">
      <div class="top-nav">
        <ul>
          <li>
            <router-link to="/naucne-videa">
              <div class="icon">
                <img src="@/assets/images/icons/naucneVidea.svg" alt="" />
              </div>
              Náučné videá
            </router-link>
          </li>
          <li>
            <router-link to="/ciselne-zapisy">
              <div class="icon">
                <img src="@/assets/images/icons/ciselneZapisy.svg" alt="" />
              </div>
              Číselné zápisy
            </router-link>
          </li>
          <li>
            <router-link to="/miesta-vyucby">
              <div class="icon">
                <img src="@/assets/images/icons/miestaVyucby.svg" alt="" />
              </div>
              Miesta výučby
            </router-link>
          </li>
          <li>
            <router-link to="/helifest">
              <div class="icon">
                <img src="@/assets/images/icons/helifest.svg" alt="" />
              </div>
              HeliFest
            </router-link>
          </li>
          <li>
            <router-link to="/helishop">
              <div class="icon">
                <img src="@/assets/images/icons/merch.svg" alt="" />
              </div>
              HeliShop
            </router-link>
          </li>
          <li>
            <router-link to="/o-nas">
              <div class="icon">
                <img src="@/assets/images/icons/oNas.svg" alt="" />
              </div>
              O nás
            </router-link>
          </li>
        </ul>
      </div>
      <div class="bot-nav">
        <ul>
          <li>
            <router-link to="/kosik">
              <div class="icon">
                <img src="@/assets/images/icons/kosik.svg" alt="" />
              </div>
              Košík</router-link
            >

            <p v-if="$store.state.userCart != 0" class="cart-number">
              {{ this.$store.state.userCart.length }}
            </p>
          </li>
          <li>
            <router-link to="/pomoc">
              <div class="icon">
                <img src="@/assets/images/icons/pomoc.svg" alt="" />
              </div>
              Pomoc</router-link
            >
          </li>
          <router-link
            v-if="$store.state.user != null"
            to="/ucebna/moje-piesne"
            class="login button prihlaseny"
          >
            <img src="@/assets/images/icons/zoznam.svg" alt="" /><router-link
              to="/ucebna/moje-piesne"
              >Zoznam piesní</router-link
            >
          </router-link>
          <router-link
            v-if="$store.state.user != null"
            to="/ucebna/moje-kurzy"
            class="login button prihlaseny"
            :class="$store.state.user.isSubscribed ? 'green' : ''"
          >
            <img src="@/assets/images/icons/online.svg" alt="" /><router-link
              to="/ucebna/moje-kurzy"
              >Online výučba</router-link
            >
          </router-link>
        </ul>
      </div>
      <router-link
        v-if="$store.state.user == null"
        to="/prihlasenie"
        class="login button"
      >
        <img
          @click="this.odhlasenie()"
          src="@/assets/images/icons/prihlasenie.svg"
          alt=""
        /><router-link to="/prihlasenie">Prihlásiť sa</router-link>
      </router-link>
      <div class="moj-ucet">
        <router-link
          v-if="$store.state.user != null"
          to="/ucebna"
          class="login no-padding button"
        >
          <img src="@/assets/images/icons/prihlasenie.svg" alt="" />
          <p>Moja učebňa</p>
          <a class="logout">
            <img
              @click="this.odhlasenie()"
              src="@/assets/images/icons/odhlasenie.svg"
              alt="odhlasenie"
            />
          </a>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    odhlasenie() {
      this.$store.dispatch("odhlasenie");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";

.button.login.no-padding {
  padding: 0;
}

.menu {
  height: 100%;
  width: 27vw;
  max-width: 20em;
  min-width: 17em;
}

.logo {
  height: 30%;
}

.navigation {
  height: 67%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.bot-nav {
  margin: auto auto 5%;
  width: 100%;
}

.top-nav {
  width: 100%;
}

.logo img {
  width: 83%;
  margin: 8% auto auto;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
}

ul {
  display: flex;
  flex-direction: column;
  align-items: center;

  a.login {
    font-size: 120%;
  }
}

.cart-number {
  background: #fef35a;
  position: absolute;
  border-radius: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.55em;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
  top: -0.3em;
  left: 1.15em;
  padding: 0.2em 0.5em 0.1em;
}

.navigation > div.bot-nav > ul > a:nth-child(4) {
  margin-top: 0.4em;
}

.navigation li {
  padding: 1.5% 2.5% 1.5% 5%;
  margin: 1% auto;

  display: flex;
  align-items: center;
  gap: 6%;
  position: relative;

  font-size: 1.5625em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.03em;

  width: 8.5em;

  &:hover {
    background: lighten($lt-gr-clr, 20%);
    transition-duration: 0.2s;
    border-radius: 0.625em;

    img {
      filter: brightness(0);
    }
  }

  img {
    width: 5em;
  }
  a {
    color: #000;
  }

  & > a {
    display: flex;
    gap: 6%;
    justify-content: flex-start;
    align-items: center;
  }
}
.icon {
  width: 10%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 1em;
  }
}

.login {
  padding: 0.4em 0.8em;
  img {
    width: 1em;
  }
}

.prihlaseny {
  padding: 0.4em 1.5em;
}

.login.no-padding {
  img {
    width: 1em;
    padding: 0.6em 0 0.6em 0.8em;
  }

  p {
    padding: 0.6em 0.4em 0.6em 0;
  }
}

li:has(.router-link-active.router-link-exact-active) {
  background: $lt-gr-clr;

  border-radius: 0.625em;

  img {
    filter: brightness(0);
  }
}

section {
  padding: 0;
}

.moj-ucet .login .logout {
  height: 100%;
  fill: #fef35a;
  filter: drop-shadow(-4px 0px 5px rgba(0, 0, 0, 0.15));
  background: url("@/assets/images/icons/elipsaOdhlasenie.svg");
  padding-right: 0.6em;
  background-position: center;
  background-size: cover;
  margin-bottom: 0 !important;
  transition-duration: 0.2s;
}

.moj-ucet .login .logout:hover {
  transform: scale(1.1);
}

.moj-ucet .login .logout img {
  width: 0.95em;
  margin-right: 0;
}

.moj-ucet p {
  font-size: 0.8em;
}

@media only screen and (max-width: 1500px) {
  .moj-ucet {
    font-size: 90%;
  }
}
</style>
